import React from "react";
import "./App.css";
import axios from "axios";
import logo from "./logo.png";
import {Link} from "react-router-dom";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
    };
    this.downloadCSV = this.downloadCSV.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  generateReport(value) {
    return `https://apivss.cartradegoprod.com/turbocharge/key/getVehicleDetailsBySecretKey/${value}`;
    // return `https://apivss.cartradegouat.com/turbocharge/key/getVehicleDetailsBySecretKey/${value}`;
    // return `https://apivss.cartradegodev.com/turbocharge/key/getVehicleDetailsBySecretKey/${value}`;
  }

  downloadCSV(value) {
    //secretcode example =
    axios
      .get(this.generateReport(value), {
        method: "GET",
        responseType: "blob",
      })
      .then((res) => {
        console.log("not error");
        console.log(res.data);

        const fileName = `CarTradeGoAgreement.pdf`;
        const a = document.createElement("a");
        document.body.appendChild(a);
        const file = new Blob([res.data], {type: "application/pdf"});
        a.href = window.URL.createObjectURL(file);
        a.download = fileName;
        a.click();
      })
      .catch((error) => {
        // console.log("error");
        console.log({error: error});
        let errors = {};
        if (error.response.status === 406) {
          errors["apierror"] = "Enter Valid Secret Code";
          console.log(error.response.status);
        } else if (error.response.status === 404) {
          errors["apierror"] = "Enter Valid Secret Code";
          console.log(error.response.status);
        } else if (error.response.status !== 200) {
          errors["apierror"] = error.response.message;
        } else {
          errors["apierror"] = "Server Error";
        }

        this.setState({
          errors: errors,
        });
      });
  }

  handleChange(event) {
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;

    this.setState({fields});

    this.validateForm();
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let valid = false;

    this.setState({
      errors: errors,
    });

    if (!fields["secretkey"]) {
      errors["secretkey"] = "Enter Valid Secret Code";
      valid = false;
      this.setState({
        errors: errors,
      });
      return valid;
    }
    console.log(valid);
    valid = true;
  }

  handleSubmit(e) {
    e.preventDefault();
    console.log(this.state.fields);
    if (this.validateForm) {
      this.downloadCSV(this.state.fields.secretkey);
    }
  }

  render() {
    return (
      <div className="App">
        <div className="header-bg">
          <div className="header">
            <div className="App-header">
              <img src={logo} alt="logo" />
              <a href="https://cartradego.com" className="homebutton main" target="_blank" rel="noopener noreferrer">
                CarTradeGo Official
              </a>
            </div>
          </div>
        </div>
        <div className="container-bottom">
          <div className="s003">
            <div className="steps">
              <h4>Step 1:</h4>
              Enter the Secret Code
            </div>
            <form onSubmit={this.handleSubmit} method="get">
              <div className="inner-form">
                <div className="input-field second-wrap">
                  <input id="search" type="text" placeholder="Enter Secret Code" value={this.state.value} onChange={this.handleChange} name="secretkey" />
                  {this.state.errors.secretkey ? <span className="errorMsg">{this.state.errors.secretkey}</span> : ""}
                  {this.state.errors.apierror ? <span className="errorMsg">{this.state.errors.apierror}</span> : ""}
                </div>
                <div className="input-field third-wrap">
                  <button className="btn-search" type="submit">
                    <svg className="svg-inline--fa fa-search fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </form>
            <div className="break"></div>
            <div className="steps">
              <h4>Step 2: </h4>
              Would you like to redeem this vehicle, Click Redeem
            </div>
            <Link to="/dealerview" className="redeemlink">
              <button className="redeembutton">Redeem</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default App;

import React from "react";
import "./Dealerview.css";
import logo from "../logo.png";
import {Link} from "react-router-dom";
import axios from "axios";
export default class DealerView extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.changeOwner = this.changeOwner.bind(this);
    this.changeOwnerAPI = this.changeOwnerAPI.bind(this);
    this.handleSubmitNewUser = this.handleSubmitNewUser.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.validateFormNewUser = this.validateFormNewUser.bind(this);
    this.handleChangeNewUser = this.handleChangeNewUser.bind(this);
  }
  changeOwnerAPI() {
    return `https://apivss.cartradegoprod.com/turbocharge/key/transferOwnership/`;
    // return `https://apivss.cartradegouat.com/turbocharge/key/transferOwnership/`;
    // return `https://apivss.cartradegodev.com/turbocharge/key/transferOwnership/`;
  }

  changeOwner(value) {
    let errors = {};
    axios
      .post(this.changeOwnerAPI(), value)
      .then((response) =>{
        console.log(response);
        if (response.status === 200) {
          errors["success"] = `Success ${response.data.message}`;
          console.log(`From 200`, response.data.message); 
        }
        this.setState({
          errors: errors,
        });
      })
      .catch((error) => {
        console.log(error);

        if (error.response.status === 400) {
          errors["apierror"] = "Enter correct information";
          console.log(error.response.status);
        }
        if (error.response.status === 406) {
          errors["apierror"] = error.response.data.message;
          console.log(errors.response.data.message);
        } else if (error.response.status !== 200) {
          errors["apierror"] = error.response.data.message;
          console.log(`From Not 200`, error.response.data.message);
        } else {
          errors["apierror"] = "Server Error";
        }

        this.setState({
          errors: errors,
        });
      });

    this.setState({
      errors: errors,
    });

    console.log(errors);
  }
  handleChange(event) {
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;

    this.setState({fields});

    this.validateForm();
  }
  handleChangeNewUser(event) {
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;

    this.setState({fields});

    this.validateFormNewUser();
  }
  validateFormNewUser() {
    let fields = this.state.fields;
    let errors = {};
    let valid = false;

    this.setState({
      errors: errors,
    });

    if (!fields["name"]) {
      errors["name"] = "Enter a valid name";
      this.setState({
        errors: errors,
      });
      return (valid = false);
    }

    if (!fields["dealershipname"]) {
      errors["dealershipname"] = "Enter a valid dealership name";
      this.setState({
        errors: errors,
      });
      return (valid = false);
    }

    if (!fields["omvic"]) {
      errors["omvic"] = "Enter a valid omvic";
      this.setState({
        errors: errors,
      });
      return (valid = false);
    }

    if (!fields["phone"]) {
      errors["phone"] = "Enter a valid phone number";
      this.setState({
        errors: errors,
      });
      return (valid = false);
    }

    if (!fields["secret"]) {
      errors["secret"] = "Enter a valid secret key";
      this.setState({
        errors: errors,
      });
      return (valid = false);
    }

    return (valid = true);
  }
  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let valid = false;

    this.setState({
      errors: errors,
    });

    if (!fields["email"]) {
      errors["email"] = "Enter Valid Email";
      valid = false;
      this.setState({
        errors: errors,
      });
      return valid;
    }
    if (!fields["password"]) {
      errors["password"] = "Enter Valid Password";
      valid = false;
      this.setState({
        errors: errors,
      });
      return valid;
    }
    if (!fields["bidPin"]) {
      errors["bidPin"] = "Enter Valid Bid Pin";
      valid = false;
      this.setState({
        errors: errors,
      });
      return valid;
    }

    if (!fields["secretKey"]) {
      errors["secretKey"] = "Enter Valid Secret Code";
      valid = false;
      this.setState({
        errors: errors,
      });
      return valid;
    }
    return (valid = true);
  }

  sendMail(value) {
    let errors = {};

    window.Email.send({
      SecureToken: "16f0fdaa-efba-4f69-ba6a-61d4b7028e25",
      To: "contactctgo@gmail.com",
      From: "<CarTradeGo> info@cartradego.com",
      Subject: "New User Secret Key Submission",
      Body: `Name : ${value.name}, Dealership Name : ${value.dealershipname}, Omvic : ${value.omvic}, Phone Number : ${value.phone}, Vehicle Secret : ${value.secret}`,
    }).then((message) => {
      if (message === "OK") {
        console.log(message);
        errors["successfornewuser"] = `${message}! Message Sent `;
        this.setState({
          errors: errors,
        });
      } else {
        console.log(message);
        errors["apierrorfornewuser"] = "Server Error";
        this.setState({
          errors: errors,
        });
      }
    });
  }
  clearInput = (e) =>{
    e.email.value ="";
    e.password.value ="";
    e.bidPin.value ="";
    e.secretKey.value ="";
  }
  clearInputNew = (e) =>{
    e.name.value ="";
    e.dealershipname.value ="";
    e.omvic.value ="";
    e.phone.value ="";
    e.secret.value ="";
  }
  handleSubmitNewUser(e) {
    e.preventDefault();
    this.validateFormNewUser();
    console.log("state", this.state.fields);
    console.log(this.validateFormNewUser());
    if (this.validateFormNewUser() === true) {
      console.log("new", this.validateFormNewUser());
      this.sendMail(this.state.fields);
      // this.clearInputNew(e.target);
    }
   
  }
  handleSubmit(e) {
    e.preventDefault();
    this.validateForm();
    console.log("state", this.state.fields);
    if (this.validateForm() === true) {
      console.log(this.validateForm());
      this.changeOwner(this.state.fields);
      // this.clearInput(e.target);
      // console.log(re);
      
    }
    // console.log(e.target)
    // console.log(this.state.errors.apierror);
  }
  render() {
    return (
      <div className="container-root">
        <div className="header-bg">
          <div className="header">
            <div className="App-header">
              <img src={logo} alt="Cartradego" />
            </div>
            <Link to="/">
              <button className="homebutton">Home</button>
            </Link>
            <a href="https://cartradego.com" className="homebutton" target="_blank" rel="noopener noreferrer">
              CarTradeGo Official
            </a>
          </div>
        </div>
        <div className="container-bottom">
          <div className="container">
            <div className="col-left">
              <h5>
                New Dealer? <br /> Fill this form
              </h5>
              <form onSubmit={this.handleSubmitNewUser}>
                <input type="text" placeholder="Name" className="inputbox" name="name" onChange={this.handleChangeNewUser} />
                {this.state.errors.name ? <span className="errorMsg inside">{this.state.errors.name}</span> : ""}
                <input type="text" placeholder="Dealership Name" className="inputbox" name="dealershipname" onChange={this.handleChangeNewUser} />
                {this.state.errors.dealershipname ? <span className="errorMsg inside">{this.state.errors.dealershipname}</span> : ""}
                <input type="text" placeholder="Omvic Number" className="inputbox" name="omvic" onChange={this.handleChangeNewUser} />
                {this.state.errors.omvic ? <span className="errorMsg inside">{this.state.errors.omvic}</span> : ""}
                <input type="text" placeholder="Phone Number" className="inputbox" name="phone" onChange={this.handleChangeNewUser} />
                {this.state.errors.phone ? <span className="errorMsg inside">{this.state.errors.phone}</span> : ""}
                <input type="text" placeholder="Vehicle Secret" className="inputbox" name="secret" onChange={this.handleChangeNewUser} />
                {this.state.errors.secret ? <span className="errorMsg inside">{this.state.errors.secret}</span> : ""}
                {this.state.errors.apierrorfornewuser ? <span className="errorMsg inside">{this.state.errors.apierrorfornewuser}</span> : ""}
                <button type="submit" className="view button-left">
                  Submit
                </button>
                {this.state.errors.successfornewuser ? <span className="successmsg inside">{this.state.errors.successfornewuser}</span> : ""}
              </form>
            </div>
            <div className="col-right">
              <h5>
                Already A Dealer? <br /> Fill this form
              </h5>
              <form method="post" onSubmit={this.handleSubmit}>
                <input type="email" placeholder="Email" className="inputbox" name="email" onChange={this.handleChange} />
                {this.state.errors.email ? <span className="errorMsg inside">{this.state.errors.email}</span> : ""}
                <input type="password" placeholder="password" className="inputbox" name="password" onChange={this.handleChange} />
                {this.state.errors.password ? <span className="errorMsg inside">{this.state.errors.password}</span> : ""}
                <input type="text" placeholder="Bidpin" className="inputbox" name="bidPin" onChange={this.handleChange} />
                {this.state.errors.bidPin ? <span className="errorMsg inside">{this.state.errors.bidPin}</span> : ""}
                <input type="text" placeholder="Secret Code" className="inputbox" name="secretKey" onChange={this.handleChange} />
                {this.state.errors.secretKey ? <span className="errorMsg inside">{this.state.errors.secretKey}</span> : ""}
                {this.state.errors.apierror ? <span className="errorMsg inside">{this.state.errors.apierror}</span> : ""}
                <button type="submit" className="view button-right">
                  Submit
                </button>
                {this.state.errors.success ? <span className="successmsg inside">{this.state.errors.success}</span> : ""}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
